import React from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import {
  preloadAs,
  preloadType,
} from "decentraland-gatsby/dist/components/Head/Preload"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"

import { CreatorsConnect } from "../components/Creators/CreatorsConnect/CreatorsConnect"
import { CreatorsCreate } from "../components/Creators/CreatorsCreate/CreatorsCreate"
import { CreatorsEarn } from "../components/Creators/CreatorsEarn/CreatorsEarn"
import { CreatorsHero } from "../components/Creators/CreatorsHero/CreatorsHero"
import { CreatorsLearn } from "../components/Creators/CreatorsLearn/CreatorsLearn"
import { CreatorsWhy } from "../components/Creators/CreatorsWhy/CreatorsWhy"
import LandingFaqs from "../components/Landing/LandingFaqs/LandingFaqs"
import { LandingFooterV2 } from "../components/Landing/LandingFooterV2/LandingFooterV2"
import Layout from "../components/Layout/Layout"
import { isWebpSupported, useImageOptimization } from "../hooks/contentful"
import useLandingContentfulQuery from "../hooks/useLandingContentfulQuery"
import BannerOpenGraph from "../images/create/create-banner.webp"
import { SectionViewedTrack } from "../modules/segment"

import "./index.css"

export enum Feed {
  Scenes,
  Events,
  Adventures,
  Multiplayer,
}

export default function CreatePage(props: any) {
  const landingContentful = useLandingContentfulQuery()
  const l = useFormatMessage()

  const imageLandscapeOptimized = useImageOptimization(
    landingContentful.creatorsHero.imageLandscape.url
  )

  const imagePortraitOptimized = useImageOptimization(
    landingContentful.creatorsHero.imagePortrait.url
  )

  return (
    <Layout {...props}>
      <Head
        title={l("page.create.social.title") || ""}
        description={l("page.create.social.description") || ""}
        image={BannerOpenGraph}
      >
        <link
          rel="preload"
          href={
            (isWebpSupported() && imageLandscapeOptimized.webp) ||
            imageLandscapeOptimized.jpg ||
            imageLandscapeOptimized.optimized
          }
          as={
            preloadAs(
              (isWebpSupported() && imageLandscapeOptimized.webp) ||
                imageLandscapeOptimized.jpg ||
                imageLandscapeOptimized.optimized!
            ) || "image"
          }
          type={preloadType(
            (isWebpSupported() && imageLandscapeOptimized.webp) ||
              imageLandscapeOptimized.jpg ||
              imageLandscapeOptimized.optimized!
          )}
        />
        <link
          rel="preload"
          href={
            (isWebpSupported() && imagePortraitOptimized.webp) ||
            imagePortraitOptimized.jpg ||
            imagePortraitOptimized.optimized
          }
          as={
            preloadAs(
              (isWebpSupported() && imagePortraitOptimized.webp) ||
                imagePortraitOptimized.jpg ||
                imagePortraitOptimized.optimized!
            ) || "image"
          }
          type={preloadType(
            (isWebpSupported() && imagePortraitOptimized.webp) ||
              imagePortraitOptimized.jpg ||
              imagePortraitOptimized.optimized!
          )}
        />
      </Head>

      <CreatorsHero item={landingContentful.creatorsHero} />

      <CreatorsWhy items={landingContentful.creatorsWhy} />

      <CreatorsCreate items={landingContentful.creatorsCreate} />

      <CreatorsConnect items={landingContentful.creatorsConnect} />

      <CreatorsLearn items={landingContentful.creatorsLearn} />

      <CreatorsEarn />

      <LandingFaqs
        className="creators-faqs"
        faqs={landingContentful.creatorsFaq}
        sectionViewed={SectionViewedTrack.CreatorsFaqs}
      />

      <LandingFooterV2 />
    </Layout>
  )
}
