import React from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import useAdvancedUserAgentData from "decentraland-gatsby/dist/hooks/useAdvancedUserAgentData"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"

import { OperativeSystem } from "../../components/Landing/LandingDownloadOptions/LandingDownloadOptions.types"
import { LandingFooterV2 } from "../../components/Landing/LandingFooterV2"
import { DownloadLayout } from "../../components/Layout/DownloadLayout"
import Layout from "../../components/Layout/Layout"
import {
  Repo,
  useLatestGithubRelease,
} from "../../hooks/useLatestGithubRelease"
import downloadImage from "../../images/download-creator-hub-banner.png"
import { FeatureFlag } from "../../modules/ff"
import { setUserAgentArchitectureDefautlByOs } from "../../modules/userAgent"

export default function DownloadPage(props: any) {
  const l = useFormatMessage()
  const searchParams = new URLSearchParams(props.location.search)
  const os = searchParams.get("os")

  const [isLoadingUserAgentData, userAgentData] = useAdvancedUserAgentData()

  if (userAgentData && os) {
    setUserAgentArchitectureDefautlByOs(userAgentData, os as OperativeSystem)
  }
  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()

  const { links, loading: isLoadingLinks } = useLatestGithubRelease(
    Repo.CREATOR_HUB
  )

  if (
    !featureFlagsLoaded ||
    featureFlagsLoading ||
    isLoadingUserAgentData ||
    isLoadingLinks ||
    !links
  ) {
    return (
      <Layout {...props} isFullscreen hideFooter={true}>
        <Head
          title={l("page.creator-hub.title") || ""}
          description={l("page.creator-hub.description") || ""}
          image="https://decentraland.org/images/decentraland.png"
        />
        <Loader active size="large" />
      </Layout>
    )
  }

  if (ff.flags[FeatureFlag.CreatorHubDownloadPage]) {
    return (
      <Layout
        {...props}
        isFullscreen
        hideFooter={true}
        className={TokenList.join([props.className, "layout__navbar--fixed"])}
      >
        <Head
          title={l("page.creator-hub.title") || ""}
          description={l("page.creator-hub.description") || ""}
          image="https://decentraland.org/images/decentraland.png"
        />
        <DownloadLayout
          productName="Decentraland Creator Hub"
          userAgentData={userAgentData}
          links={links}
          title={l("page.creator-hub.download.title")}
          image={downloadImage}
          imageObjectFit="contain"
          redirectPath={"/download/creator-hub-success"}
        />
        <LandingFooterV2 />
      </Layout>
    )
  }

  return null
}
